import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import LogRocket from 'logrocket';

import { AppModule } from 'app/app.module';
import { APP_VERSION } from '@constants/app-version.constant';
import { environment as ENV } from '@environments/environment';

if (ENV.production) {
  enableProdMode();
}

if (ENV.logRocket.enabled) {
  LogRocket.init(ENV.logRocket.projectId, {
    release: APP_VERSION,
  });
}

if (ENV.sentry.enabled) {
  Sentry.init({
    environment: ENV.environmentName,
    dsn: ENV.sentry.apiKey,
    release: APP_VERSION,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: ENV.production ? 1 : 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: ENV.production ? 1 : 0,

    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: ['services.partners.cargamos.com'],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
      new Sentry.Replay({
        maskAllText: false,
        maskAllInputs: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          window.location.origin,
          'https://services.partners.cargamos.com',
          /^https:\/\/services\.partners\.cargsmos\.com/,
        ],
        networkCaptureBodies: true,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
