import { NgModule, APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { TitleStrategy } from '@angular/router';
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import * as Sentry from '@sentry/angular-ivy';
import { BrowserModule } from '@angular/platform-browser';
import { map, switchMap, tap } from 'rxjs/operators';
import { Auth } from '@angular/fire/auth';
import LogRocket from 'logrocket';

import { environment as ENV } from '@environments/environment';
import { AuthService } from '@modules/auth/services/auth.service';
import { PermissionService } from '@services/permission.service';
import { AuthInterceptor } from '@core/interceptors/auth.interceptor';
import { PageTitleStrategy } from '@modules/core/resolvers/page-title.resolver';

let extraProviders: Array<Provider> = [];

function initializeAppFactory(
  authService: AuthService,
  permissionService: PermissionService,
  auth: Auth
) {
  if (!authService.isUserLoggedIn()) {
    return () => Promise.resolve();
  }

  return (): Promise<void> => {
    return new Promise((resolve): void => {
      auth.onAuthStateChanged(user => {
        if (user) {
          authService
            .refreshToken()
            .pipe(
              tap(session => {
                authService.saveAuthToken(session || '');
              }),
              switchMap(() => authService.getUserSessionInfo()),
              map(session => session.data)
            )
            .subscribe({
              next: me => {
                if (me) {
                  const { user } = me;
                  if (ENV.logRocket.enabled) {
                    LogRocket.identify(me.user._id, {
                      email: user.email,
                      name: `${user.display_name}`,
                      tenantId: user.tenant_id,
                    });
                  }
                  if (ENV.sentry.enabled) {
                    Sentry.setUser({
                      email: user.email,
                      id: me.user._id,
                      tenantId: user.tenant_id,
                      name: `${user.display_name}`,
                    });
                  }
                  authService.loadMeSession(me);
                  return resolve();
                }
                return resolve();
              },
              error: () => {
                permissionService.loadPermissions([]);
                return resolve();
              },
            });
        } else {
          return resolve();
        }
      });
    });
  };
}

if (ENV.sentry.enabled) {
  extraProviders = [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ];
}

@NgModule({
  imports: [CommonModule, BrowserModule, BrowserAnimationsModule, HttpClientModule],
  providers: [
    {
      provide: TitleStrategy,
      useClass: PageTitleStrategy,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AuthService, PermissionService, Auth],
      multi: true,
    },
    ...extraProviders,
  ],
})
export class CoreModule {}
